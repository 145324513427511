
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { PlanReportAdd, MonthPlanList } from '@/types/operationMonthReport.d'

@Component
export default class extends Vue {
  private formData: PlanReportAdd = {
    planType: '3',
    projectId: '',
    planStart: '',
    planEnd: '',
    status: '',
    weekPlanList: [],
    monthPlanList: []
  }

  private rangeDate: Array<string> = []
  private submitShow = false
  private loading = false
  private isEdit: number | null = null
  private oldData: { [key: number]: MonthPlanList } = {}
  private rules = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: 'change' }
    ],
    planStart: [
      { required: true, message: '请选择计划周期', trigger: 'change' }
    ],
    monthPlanList: [
      { required: true, message: '请添加月工作明细', trigger: 'change' }
    ]
  }

  private tableRules = {
    position: [
      { required: true, message: '该字段不可为空', trigger: 'change' }
    ],
    plant: [
      { required: true, message: '该字段不可为空', trigger: 'change' }
    ],
    content: [
      { required: true, message: '该字段不可为空', trigger: 'change' }
    ],
    person: [
      { required: true, message: '该字段不可为空', trigger: 'change' }
    ],
    weekOne: [
      { required: true, message: '该字段不可为空', trigger: 'change' }
    ],
    weekTwo: [
      { required: true, message: '该字段不可为空', trigger: 'change' }
    ],
    weekThree: [
      { required: true, message: '该字段不可为空', trigger: 'change' }
    ],
    weekFour: [
      { required: true, message: '该字段不可为空', trigger: 'change' }
    ],
    weekFive: [
      { required: true, message: '该字段不可为空', trigger: 'change' }
    ],
    complete: [
      { required: true, message: '该字段不可为空', trigger: 'change' }
    ],
    verifier: [
      { required: true, message: '该字段不可为空', trigger: 'change' }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  get reportId () {
    return this.$route.params.reportId || ''
  }

  created () {
    this.reportId && this.getDetail()
  }

  getDetail () {
    this.loading = true
    this.$axios.get(this.$apis.operationmanage.selectPlanReportByReportId, { reportId: this.reportId }).then((res) => {
      this.formData = res
      this.rangeDate = [this.formData.planStart, this.formData.planEnd]
      this.formData.monthPlanList.forEach((item) => {
        item.isInput = false
      })
      this.formData.monthPlanList = JSON.parse(JSON.stringify(this.formData.monthPlanList))
    }).finally(() => {
      this.loading = false
    })
  }

  // 展示时间
  changetimers (val: string[]) {
    console.log(val)
    if (val && val[0] && val[1]) {
      this.formData.planStart = val[0]
      this.formData.planEnd = val[1]
    } else {
      this.formData.planStart = ''
      this.formData.planEnd = ''
    }
  }

  // 列表添加
  onAdd () {
    if (this.isEdit !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.formData.monthPlanList.push({
        position: '',
        plant: '',
        content: '',
        person: '',
        weekOne: '',
        weekTwo: '',
        weekThree: '',
        weekFour: '',
        weekFive: '',
        complete: '',
        verifier: '',
        isInput: true
      })
      this.$nextTick(() => {
        // 滚动到表格最底部
        ;(this.$refs.editTable as any).bodyWrapper.scrollTop = (this.$refs.editTable as any).bodyWrapper.scrollHeight
      })
      this.isEdit = this.formData.monthPlanList.length - 1
    }
  }

  // 保存当条
  onSave (row: MonthPlanList, index: number) {
    ;(this.$refs.addTable as ElForm).validate((valid) => {
      if (valid) {
        row.isInput = false
        this.$set(this.formData.monthPlanList, index, this.formData.monthPlanList[index]) // 重新渲染表格数据
        this.isEdit = null
      }
    })
  }

  // 列表编辑当条
  onEdit (row: MonthPlanList, index: number) {
    if (this.isEdit !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.oldData[index] = row
      row.isInput = true
      this.isEdit = index
      this.$set(this.formData.monthPlanList, index, this.formData.monthPlanList[index]) // 重新渲染表格数据
    }
  }

  // 当条取消编辑
  onCancel (row: MonthPlanList, index: number) {
    this.isEdit = null
    if (this.oldData[index]) {
      // 是否为编辑
      this.formData.monthPlanList[index] = this.oldData[index]
      this.formData.monthPlanList[index].isInput = false // 取消值不变
      this.$set(this.formData.monthPlanList, index, this.formData.monthPlanList[index]) // 重新渲染表格数据
    } else {
      this.formData.monthPlanList.splice(index, 1)
    }
  }

  // 删除当条
  onDelete (index: number) {
    if (this.isEdit !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.formData.monthPlanList.splice(index, 1)
        this.$message.success('删除成功')
      })
    }
  }

  onSubmit (num: string) {
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        if (this.isEdit !== null) {
          this.$message.warning('请先保存！')
        } else {
          this.formData.monthPlanList.forEach(item => {
            delete item.isInput
          })
          this.formData.status = num
          this.submitShow = true
          const url = this.reportId
            ? this.$apis.operationmanage.updatePlanReport
            : this.$apis.operationmanage.insertPlanReport
          this.$axios.post(url, {
            ...this.formData
          }).then(() => {
            this.$message.success('添加成功')
            this.closeAdd()
          }).catch(() => {
            this.formData.monthPlanList.forEach(item => {
              item.isInput = false
            })
          }).finally(() => {
            this.submitShow = false
          })
        }
      }
    })
  }

  // 关闭
  closeAdd () {
    this.$router.back()
  }
}
